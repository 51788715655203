import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { title } from "process";

@Component({
  selector: "app-store-promotions",
  templateUrl: "./store-promotions.component.html",
  styleUrls: ["./store-promotions.component.scss"],
})
export class StorePromotionsComponent implements OnInit {
  customOptions: OwlOptions;
  items: {
    title: string;
    description?: string;
    buttonText?: string;
    image: string;
    url: string;
    country: string;
    active: boolean;
  }[];
  customOptionsInput: OwlOptions;

  @Input() currentCountry: string;

  currentCountryItems: {
    title: string;
    description?: string;
    buttonText?: string;
    image: string;
    url: string;
    country: string;
    active: boolean;
  }[];

  constructor() {
    this.customOptions = {
      autoplay: true,
      autoplayHoverPause: true,
      autoplayMouseleaveTimeout: 5000,
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 700,
      navText: [
        '<img src="/assets/images/back.png">',
        '<img src="/assets/images/next.png">',
      ],
      responsive: {
        0: {
          items: 1,
        },
        550: {
          items: 2,
        },
        920: {
          items: 3,
        },
        1200: {
          items: 4,
        },
      },
      nav: true,
    };

    // Test data

    // //Test data
    // this.items = [];
    // for (let i = 0; i < 10; i++) {
    //   this.items.push({
    //     title: 'Titulo Promo ' + i,
    //     description: 'Descripcion ' + i,
    //     image: 'https://via.placeholder.com/500',
    //     url: 'https://www.example.com/promo' + i
    //   });
    // }

    // Cuot Sale
    // this.items = [
    //   {
    //     title: 'Promo 1',
    //     description: 'Descripcion 1',
    //     buttonText: 'Ir a la tienda',
    //     image: '/assets/images/cuotSale/farmacias-red.jpg',
    //     url: 'https://farmaciasred.com.ar'
    //   },
    //   {
    //     title: 'Promo 2',
    //     description: 'Descripcion 2',
    //     buttonText: 'Ir a la tienda',
    //     image: '/assets/images/cuotSale/motorola.jpg',
    //     url: 'https://www.motorola.com.ar/'
    //   },
    //   {
    //     title: 'Promo 3',
    //     description: 'Descripcion 3',
    //     buttonText: 'Ir a la tienda',
    //     image: '/assets/images/cuotSale/newsan.jpg',
    //     url: 'https://tiendanewsan.com.ar'
    //   },
    //   {
    //     title: 'Promo 4',
    //     description: 'Descripcion 4',
    //     buttonText: 'Ir a la tienda',
    //     image: '/assets/images/cuotSale/rossetti.jpg',
    //     url: 'https://rossettideportes.com.ar'
    //   }
    // ];

    // Por el momento esta lista de items esta hardcodeada, pero se deja preparado
    // para que en un futuro se pueda traer de la base de datos
    this.items = [
      {
        title: "Baires It",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/1.png",
        url: "https://www.basicosbb.com.ar/",
        country: "AR",
        active: true,
      },
      {
        title: "Farmamix",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/2.png",
        url: "https://www.poisonheart.com.ar/",
        country: "AR",
        active: true,
      },
      {
        title: "Henzy",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/3.png",
        url: "https://www.perfumeriasrouge.com/",
        country: "AR",
        active: true,
      },
      {
        title: "Punto Deportivo",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/4.png",
        url: "https://www.henzy.com.ar/",
        country: "AR",
        active: true,
      },
      {
        title: "One Store",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/5.png",
        url: "https://www.libreriasmatildashop.com.ar/",
        country: "AR",
        active: true,
      },
      {
        title: "Show Sport",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/6.png",
        url: "https://www.alcostocalzado.com/",
        country: "AR",
        active: true,
      },
      {
        title: "Farmacias del Pueblo",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/7.png",
        url: "https://www.rossettideportes.com/",
        country: "AR",
        active: true,
      },
      {
        title: "Rossetti Deportes",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/8.png",
        url: "https://www.farmaciageneralpaz.com/",
        country: "AR",
        active: true,
      },
      {
        title: "Tienda Newsan",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/9.png",
        url: "https://mijugueteria.ar/",
        country: "AR",
        active: true,
      },
      {
        title: "Tienda Newsan",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/10.png",
        url: "https://www.farmamix.com.ar/",
        country: "AR",
        active: true,
      },
      {
        title: "Tienda Newsan",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/11.png",
        url: "https://www.onestore.com.ar/",
        country: "AR",
        active: true,
      },
      {
        title: "Tienda Newsan",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/12.png",
        url: "https://www.showsport.com.ar/",
        country: "AR",
        active: true,
      },
      // -------------------------- CHILE -------------------------------
      {
        title: "Foxy Smart",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/1clp.png",
        url: "https://www.kross.cl/",
        country: "CL",
        active: true,
      },
      {
        title: "Tienda Mademsa",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/2clp.png",
        url: "https://www.intime.cl/ ",
        country: "CL",
        active: true,
      },
      {
        title: "Santa Rita",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/3clp.png",
        url: "https://sipoonline.cl/product-category/hogar-y-oficina/",
        country: "CL",
        active: true,
      },
      {
        title: "Intime",
        buttonText: "Ir a la tienda",
        image: "/assets/images/Tiendas/promos/4clp.png",
        url: "https://pegasum.cl/sillas/ ",
        country: "CL",
        active: true,
      }
    ];
  }

  // Este filtro se ejecuta cada vez que se cambia el valor de la variable currentCountry
  // Idealmente deberia ser un observable que se suscriba a un servicio que traiga
  // los datos de la base de datos ya filtrados
  ngOnInit(): void {
    this.currentCountryItems = this.items.filter(
      (item) => item.country === this.currentCountry && item.active === true
    );
  }

  public navigate(url: string): void {
    window.open(url, "_blank");
  }
}
